<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row margin-bottom-20">
                <div class="col-xs-5">
                    <h1 class="h2 margin-bottom-20 hide">Prenotazioni Ricevute</h1>
                    <a class="btn btn-default" href="" @click.prevent.stop="visibleReservationFilterModal=true" data-toggle="modal" data-target="#FilterReservationsModal">
                        <span class="glyphicon glyphicon-sort-by-attributes-alt" aria-hidden="true"></span>
                        Filtri <span id="filters-counter"><span v-show="counterFilters > 0" class="filter-spacer">|</span> <span class="filter-active" v-show="counterFilters > 0">{{counterFilters}}</span></span>
                    </a>

                </div>

                <div class="col-xs-7 text-right">
<!--                    <a class="btn btn-default margin-right-20" href="" @click.prevent.stop="visibleValidationTicketFilterModal=true" data-toggle="modal" data-target="#FilterValidateTicketModal">-->
<!--                        <span class="glyphicon glyphicon-check" aria-hidden="true"></span>-->
<!--                        Convalida Biglietto-->
<!--                    </a>-->
                    <router-link class="btn btn-primary" :to="{name: 'booking'}">
                        <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
                        Nuova Prenotazione
                    </router-link>
                </div>
            </div>
            <ReservationTable :reservations="reservations"/>
            <div class="text-center">
                <Pagination v-if="enabledPagination" :page-count="pagesElements" :click-handler="paginateCallback"/>
            </div>
        </div>
        <ReservationFilterModal 
            @close-filter-reservations-modal="visibleReservationFilterModal=false"
            @filter-reservation-callback="filterReservationCallback"
            @remove-filter-reservation-callback="filterReservationCallback"
            :visible="visibleReservationFilterModal"
        ></ReservationFilterModal>
        <ValidationTicketFilterModal
                @close-validate-ticket-reservations-modal="visibleValidationTicketFilterModal=false"
                @filter-reservation-callback="filterValidateTicketCallback"
                @remove-filter-reservation-callback="filterReservationCallback"
                :visible="visibleValidationTicketFilterModal"
        ></ValidationTicketFilterModal>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import ReservationTable from "../components/Reservation/ReservationTable"
    import {getReservations, getReservationToConvalidate} from "../api"
    import Pagination from "../components/common/Pagination"
    import baseMixin from "../common/baseMixin"
    import ReservationFilterModal from "../components/modal/ReservationFilterModal"
    import ValidationTicketFilterModal from "../components/modal/ValidationTicketFilterModal"

    export default {
        title: "Prenotazioni",
        name: "Reservations",
        components: {ContainerWithMenu,ReservationTable,Pagination,ReservationFilterModal,ValidationTicketFilterModal},
        mixins: [baseMixin],
        data: function() {
            return {
                loading: false,
                reservations: [],
                visibleReservationFilterModal: false,
                visibleValidationTicketFilterModal: false,
                filterEnabled: {},
                dateFilter: "today",
                enabledPagination: true
            }
        },
        mounted() {
            this.filterEnabled = this.Utils.getStartEndDateFromString(this.dateFilter);
            this.getReservations();
        },

        computed: {
            counterFilters: function () {
                let counterFilters = 0;

                for (const [,value] of Object.entries(this.filterEnabled)) {
                    if (value) {
                        counterFilters++
                    }
                }

                if (this.filterEnabled.start_date && this.filterEnabled.end_date) {
                    counterFilters--;
                }

                return counterFilters

            }
        },
        methods: {
            filterValidateTicketCallback(filter){
                this.filterEnabled = filter;
                this.visibleValidationTicketFilterModal = false;
                this.getReservationToConvalidate(1, filter);
                this.enabledPagination = false;
                this.$nextTick(() => {
                    this.enabledPagination = true;
                });
            },
            filterReservationCallback(filter) {
                this.filterEnabled = filter;
                this.visibleReservationFilterModal = false;
                this.visibleValidationTicketFilterModal = false;
                this.getReservations(1, filter);
                this.enabledPagination = false;
                this.$nextTick(() => {
                    this.enabledPagination = true;
                });
            },
            paginateCallback(pageNum) {
                this.getReservations(pageNum);
            },
            async getReservations(page = 1, filter=this.filterEnabled) {
                this.loading = true;
                try {
                    let reservationsResponse = await getReservations(page, filter);
                    this.countElements = reservationsResponse.count;
                    this.reservations = reservationsResponse.results;
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async getReservationToConvalidate(page = 1, filter=this.filterEnabled) {
                this.loading = true;
                try {
                    let reservationsResponse = await getReservationToConvalidate(page, filter);
                    this.countElements = reservationsResponse.count;
                    this.reservations = reservationsResponse.results;
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            }
        }
    };
</script>
