<template>
    <tr class="clickable-row">
        <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{reservation.id}}</router-link></td>
        <td v-if="reservation.channel === Keys.RESERVATION_CHANNEL.OFFLINE"><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{Utils.getDateStringFromISO(reservation.date)}}</router-link></td>
        <td v-else><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{Utils.getShortDateStringFromISO(reservation.date)}}</router-link></td>
        <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{StringsHelper.getOfferta(reservation)}}</router-link></td>
        <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}"> <span v-if="reservation.creator">{{reservation.creator.display_name}}</span><span v-else>{{reservation.customer_first_name}} {{reservation.customer_last_name}}</span></router-link></td>
        <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}"><span class="label label-default"> {{StringsHelper.getChannel(reservation.channel)}}</span></router-link></td>
        <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">
            <span v-if="!reservation.check_in && reservation.state === Keys.RESERVATION_STATES.COMPLETED && reservation.channel !== Keys.RESERVATION_CHANNEL.OFFLINE " class="label label-primary" >
                Da Convalidare
            </span>
            <span v-else class="label" :class="LabelClassHelper.getLabelClassReservationState(reservation.state)">
                <span v-if="reservation.check_in && reservation.state === Keys.RESERVATION_STATES.COMPLETED">
                    Convalidato
                </span>
                <span v-else>
                    {{StringsHelper.getReservationState(reservation.state)}}
                </span>
            </span>


        </router-link></td>
        <td><router-link :to="{name:'reservation_detail', params: {reservation_id: reservation.id}}">{{StringsHelper.getPaymentMode(reservation.payment_mode)}}</router-link></td>
    </tr>
</template>

<script>
    
    import baseMixin from '@/common/baseMixin'

    export default {
        name: "ReservationRow",
        mixins: [baseMixin],
        props: {
            reservation: {
                type: Object,
                required: true
            }
        },
      methods:{

      }
    }
</script>
