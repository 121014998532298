<template>

    <ModalContainer :visible="visible" :loading="loading">
        <template v-slot:header>
           <a class="btn btn-default btn-sm" id="delete_filters" @click.prevent.stop="removeFilterReservations">
            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                Cancella Filtri
            </a>
            <button type="button" class="close" @click="$emit('close-validate-ticket-reservations-modal')"><span aria-hidden="true">&times;</span></button>
        </template>
        <template v-slot:body>
            <div class="form-horizontal">

                <div class="form-group">
                    <label for="ticket_code" class="col-xs-4 control-label">Codice biglietto</label>
                    <div class="col-xs-8">
                        <input class="form-control" type="text" id="ticket_code" v-model="filter.ticket_code">
                    </div>
                </div>
                <div class="form-group">
                    <label for="customer_name" class="col-xs-4 control-label">Cognome cliente</label>
                    <div class="col-xs-8">
                        <input class="form-control" type="text" id="customer_name" v-model="filter.customer_name">
                    </div>
                </div>

            </div>
        </template>
        <template v-slot:footer>
            <a @click.prevent.stop="filterReservations" class="btn btn-primary btn-lg btn-block" id="apply_filters">
                <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                Applica Filtri
            </a>
        </template>
    </ModalContainer>

</template>

<script>
    import ModalContainer from "./ModalContainer"
    import {COUNTRIES} from "../../common/countries";
    import baseMixin from "../../common/baseMixin";
    export default {
        name: "ValidationTicketFilterModal",
        components: { ModalContainer },
        mixins: [baseMixin],
        props: {
            visible: {
                type: Boolean,
                required: true
            }
        },
        data: () => {
            return {
                COUNTRIES: COUNTRIES,
                loading: false,
                date: null,
                creatorsOptions: [],
                paymentOptions: [],
                dateOptions: [],
                filter: {
                    ticket_code: null,
                    customer_name: null,
                },
                defaultFilter: {
                    ticket_code: null,
                    customer_name: null,
                 }
            }
        },
        methods: {
            filterReservations() {
                this.$emit('filter-reservation-callback', this.filter)
            },
            removeFilterReservations() {
            this.$emit('remove-filter-reservation-callback', this.defaultFilter)
          },
        }
    }
</script>
